import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import Box from "../containers/box";
import Container from "../containers/container";
import Heading from "../typography/heading";
import Text from "../typography/text";
import Button from "../buttons/button";
import Link from "../links/link";
import LinkButton from "../links/linkButton";
import Circle from "../globals/circle";

const Root = styled(Box)`
  text-align: center;

  button {
    margin-top: 30px;
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 0 6px 0;
    max-width: 180px;
    border-radius: 0;

    @media (min-width: ${p => p.theme.screenL}) {
      margin-top: 42px;
    }
  }
`;

const ContactFormResponse = ({ setFormFired, error, theme }) => {
  const failedFormResponse = (
    <Container textAlign="center">
      <Heading as="h4" color={theme.red} mx="auto" mb={[36, null, null, 48]}>
        Sending Failed
      </Heading>

      <Text color={theme.white} maxWidth={474} mb={[30, null, null, 42]}>
        Sending the form has failed. <br />
        You can still get in touch through either our social media (links can be
        found in the footer) or by emailing us at,{` `}
        <Link
          as="a"
          color={theme.white}
          href="mailto:help@toptekkers.com?subject=User getting in touch. Contact form didn't work."
        >
          help@toptekkers.com
        </Link>
        .
      </Text>

      <LinkButton as="a" href="/" mb={[30, null, null, 42]}>
        Back to the home page
      </LinkButton>

      <Button mx="auto" onClick={setFormFired}>
        Make another enquiry
      </Button>
    </Container>
  );

  const successfulFormResponse = (
    <Container textAlign="center">
      <Heading as="h4" color={theme.green} mx="auto" mb={[36, null, null, 48]}>
        Thank you
      </Heading>

      <Text color={theme.white} maxWidth={474} mb={[30, null, null, 42]}>
        Thank you for contacting us, we’ll be in touch with a response as soon
        as possible.
      </Text>

      <LinkButton as="a" href="/" mb={[30, null, null, 42]}>
        Back to the home page
      </LinkButton>

      <Button mx="auto" onClick={setFormFired}>
        Make another enquiry
      </Button>
    </Container>
  );

  return (
    <Root mt={[72, null, null, 84]} error={error}>
      {!error ? successfulFormResponse : failedFormResponse}

      <Circle
        grow
        bgColor={theme.orange}
        top={[-114, null, -130, -174]}
        right="10%"
      />
      <Circle
        grow
        bgColor={theme.purple}
        bottom="20%"
        right={[-30, null, -46, -90]}
      />
      <Circle
        growMid
        size="l"
        bgColor={theme.blue}
        top={[-114, null, -130]}
        left="10%"
      />
      <Circle
        growMid
        size="l"
        bgColor={theme.pink}
        bottom="40%"
        left={[-30, null, -46]}
      />
      <Circle
        grow
        bgColor={theme.yellow}
        bottom={[-30, null, -46, -90]}
        left="18%"
      />
    </Root>
  );
};

ContactFormResponse.propTypes = {
  setFormFired: PropTypes.func.isRequired,
  error: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ContactFormResponse);
