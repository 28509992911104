import handleErrors from "../utils/handleErrors"
import { getApiPath } from "../utils/getPath"

export async function createHubspotTicket(values) {
  const { subject, firstname, lastname, email, message } = values
  const body = {
    issue_title: subject,
    first_name: firstname,
    last_name: lastname,
    email_address: email,
    phone_number: ``,
    issue_type: `General`,
    issue_details: message,
    source: `TT_FORM`,
  }

  try {
    const response = await fetch(
      ` https://us-central1-toptekkers-private.cloudfunctions.net/generate_hubspot_issue`,
      {
        method: `POST`,
        body: JSON.stringify(body),
        headers: {
          "Content-Type": `application/json`,
          Accept: `application/json`,
        },
      }
    )

    handleErrors(await response)
  } catch (e) {
    throw e
  }
}

export async function createHubspotDeal(values) {
  const {
    firstName,
    lastName,
    email,
    message,
    licenceCount,
    currencyCode,
    priceQuoted,
  } = values

  const body = {
    first_name: firstName,
    last_name: lastName,
    email,
    extra_info: message,
    num_of_licences: licenceCount,
    currency_code: currencyCode,
    quote_amount: priceQuoted,
  }

  try {
    const response = await fetch(`${getApiPath()}api/hubspot/deal/add`, {
      method: `POST`,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": `application/json`,
        Accept: `application/json`,
      },
    })

    handleErrors(await response)
  } catch (e) {
    throw e
  }
}
